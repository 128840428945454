import styled from 'styled-components';
import { useEffect } from 'react';
const SplashContainer = styled.div`
  margin-top: -2rem;

`;

const Keyword = styled.span`
  font-weight: bold;
  color: var(--your-custom-color);
  position: relative;
  margin-right: 0.2rem;

  ::before {
    content: '\u25A0';
    font-size: 0.8rem;
    position: absolute;
    top: 0;
    left: -0.5rem;
    color: var(--your-custom-color);
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  // color: var(--global-text);
  color: white;
  background-color: darkgray;
`;

const MainContent = styled.div`
  max-width: 50rem;
  margin: 0 auto;
  padding: 1rem;
  color: var(--global-text);
  font-size: 1rem;
  line-height: 1.6;
  background-color: gray;
`;

const sections = [
  {
    title: 'Donate',
    title2: 'Zero-fee donations: Your support helps us keep the site running.',
    title3: 'Cash App',
    content: (
      <Paragraph>
        <Keyword>Link: </Keyword>
        <a href='https://cash.app/$Zyhroxrr'>https://cash.app/$Zyhroxrr</a>
      </Paragraph>
    ),
  },
  {
    title2: 'Kofi',
    content: (
      <Paragraph>
        <Keyword>Link: </Keyword>
        <a href='https://ko-fi.com/milorr'>https://ko-fi.com/milorr</a>
      </Paragraph>
    ),
  },
  {
    title2: 'Zelle',
    content: (
      <Paragraph>
        <Keyword>Link: </Keyword>
        <a href='https://cash.app/$Zyhroxrr'>https://cash.app/$Zyhroxrr</a>
      </Paragraph>
    ),
  },
  {
    title2: 'BTC',
    content: (
      <>
        <Paragraph>
          <strong>Address:</strong> bc1q5977wvmv5qdhxcnmj5cnrx0wzdf0van2srncs6
        </Paragraph>
      </>
    ),
  },
];

function Donations() {
  useEffect(() => {
    document.title = 'Donate | Milo'; // Set the title when the component mounts
  }, []);

  return (
    <SplashContainer>
      <MainContent>
        <br />
        {sections.map((section, index) => (
          <span key={index}>
            {section.title && <h1 className='title-style'>{section.title}</h1>}
            {section.title2 && (
              <h3 className='title-style'>{section.title2}</h3>
            )}
            {section.title3 && (
              <h3 className='title-style'>{section.title3}</h3>
            )}
            {section.content}
          </span>
        ))}
      </MainContent>
    </SplashContainer>
  );
}

export default Donations;
