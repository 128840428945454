export const PromoNav = () => {
  return (
    <header className='z-50 mx-auto flex items-center justify-center space-x-8 py-5'>
      <ul className='flex space-x-8 font-bold'>
        <li>
          <a
            href='https://www.youtube.com/watch?v=yDDBaQnd9Ls'
            className='text-black hover:text-gray-800'
          >
            Check out our store here.
          </a>
        </li>
      </ul>
      <a
        href='https://www.youtube.com/watch?v=yDDBaQnd9Ls'
        className='rounded bg-white px-6 py-2 font-semibold text-blue-600 hover:bg-blue-500 hover:text-white'
      >
        Shop Now
      </a>
    </header>
  );
};
