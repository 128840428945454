import { useEffect, useRef } from 'react';

export const KofiFloatingChatWidget = () => {
  const widgetRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://storage.ko-fi.com/cdn/scripts/overlay-widget.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      kofiWidgetOverlay.draw('milorr', {
        type: 'floating-chat',
        'floating-chat.donateButton.text': 'Support me',
        'floating-chat.donateButton.background-color': '#00b9fe',
        'floating-chat.donateButton.text-color': '#fff',
      });
    };
    widgetRef.current.appendChild(script);
  }, []);

  return <div ref={widgetRef}></div>;
};
