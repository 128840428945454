import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import 'swiper/swiper-bundle.css';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';

posthog.init('phc_2Ej5YcWXW6z95kWvGFkvdLFJHSLqmgSgkCDXJspwiMF', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only',
  capture_pageview: true,
});

posthog.capture('★AboutPageView', {
  page_title: 'About',
  page_url: 'https://ohmilo.xyz/about',
  page_path: '/about',
  // page_referrer: 'https://ohmilo.xyz',
  host: 'https://ohmilo.xyz',
});

posthog.capture('button_clicked', {
  button_id: 'button-1',
  button_label: 'Click me',
  button_type: 'button',
  button_action: 'https://ohmilo.xyz/about',
  button_category: 'button',
  button_label_text: 'Click me',
  action: 'https://ohmilo.xyz/about',
  action2: 'click me',
  $height: '100px',
  $width: '100px',
});

Sentry.init({
  dsn: 'https://8c105966e7dfdaaf90900beea09abbd3@o4507298653863936.ingest.us.sentry.io/4507383306584064',
  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
    Sentry.replayIntegration(),
    Sentry.replayCanvasIntegration(),
  ],
  tracesSampleRate: 0.5,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
