import { MyLikes } from '@/components/MyLikes';
// import { Overlay } from "./components/Overlay";
import { Projects } from '@/components/Projects';
// import { Button } from '@/components/ui/button';
// import { Pricing } from '@/components/Pricing';
// import { MessageCircle } from 'lucide-react';
import { useEffect } from 'react';
// import gsap from 'gsap';
// import ScrollTrigger from 'gsap/ScrollTrigger';
// import Flip from 'gsap/Flip';
// import Draggable from 'gsap/Draggable';
import AnimeGirl from '@/assets/Girl.json';
import Lottie from 'lottie-react';
import { GsapExample } from '@/components/Gsap';
import { Sniply } from '..';

function Home() {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = 'Milos Portfolio';
    return () => {
      document.title = previousTitle;
    };
  }, []);

  return (
    <>
      {/* <Overlay /> */}

      <div className='flex flex-col items-center justify-center'>
        <a href='https://ko-fi.com/P5P610HV8F' target='_blank'>
          <img
            height='36'
            width={'500'}
            className='kofi-button'
            src='https://cdn.prod.website-files.com/5c14e387dab576fe667689cf/64f1a9ddd0246590df69ea01_kofi_long_button_blue%25402x-p-500.png'
            alt='Buy Me a Coffee at ko-fi.com'
          />
        </a>
        <Sniply />
        <h1 className='text-2xl'>
          <span className='text-red-500'>★Welcome to my website!</span>
        </h1>
        <MyLikes />
        <Projects className='w-[100px]' />
        {/* <GsapExample /> */}
        {/* <div className='pointer-events-none relative flex h-full w-full items-center justify-center overflow-hidden'>
        <img
          src='https://act-webstatic.hoyoverse.com/puzzle/hkrpg/pz_3z780LOFem/resource/puzzle/2024/03/29/a226a2b42bd6035d07ccfbbe7432f98e_4515112973596349785.png?x-oss-process=image/format,webp/quality,Q_90'
          className='absolute; width: 19.2rem; height: 11.02rem; left: 0rem; top: 0rem; font-size: 72.9167px; pointer-events-none z-10 h-full w-full cursor-crosshair'
        ></img>
        <img
          src='https://pbs.twimg.com/media/GOxR2cHXgAAs6Yh?format=jpg&name=4096x4096'
          className='absolute left-0 z-0 h-full w-full object-cover'
        ></img>
        <img
          src='https://fastcdn.hoyoverse.com/mi18n/hkrpg_global/m20240619hy3959a7sw/upload/1fb05c0e175662facd48fe814de754c7_3638935155827358988.png?x-oss-process=image/format,webp/quality,Q_90'
          className='width: 8.9rem; height: 1.8rem; right: 0.24rem; top: 9.14rem; font-size: 72.9167px; z-5 absolute'
        ></img>
      </div> */}
        <div>Currently in production</div>
        <div>I make static and dynamic websites.</div>
        {/* <a href='https://buy.stripe.com/14k6rrben6xNeD63cd' target='_blank'>
          <img
            height='36'
            width={'500'}
            className='kofi-button'
            src='https://cdn.prod.website-files.com/5c14e387dab576fe667689cf/64f1a9ddd0246590df69ea01_kofi_long_button_blue%25402x-p-500.png'
            alt='Buy Me a Coffee at ko-fi.com'
          />
        </a> */}
        {/* <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='1280'
            height='708'
            src='https://www.youtube.com/embed/a2k2buMMjJw?list=RDxh1MKfA12cY'
            title='【MAD】銀魂 あなたの夜が明けるまで×坂田銀時'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='1280'
            height='720'
            src='https://www.youtube.com/embed/trX9TiYH-Kk?'
            title='【銀魂】完結?!!!!! | Gintama Full Story?! | [MAD] | SPYAIR "I Wanna Be..."'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          ></iframe>
        </div>
      </section>
      <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='1280'
            height='720'
            src='https://www.youtube.com/embed/MExpJUwGtTI?list=RDxh1MKfA12cY'
            title='〖 MAD 〗銀魂 × I Wanna Be…'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='1280'
            height='720'
            src='https://www.youtube.com/embed/POmXPjHsdD4?list=RDxh1MKfA12cY'
            title='【MAD】銀魂 THE FINAL × サクラミツツキ'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='1280'
            height='720'
            src='https://www.youtube.com/embed/LtDIJJFwOi8?list=RDxh1MKfA12cY'
            title='【MAD】銀魂×灰色と青【〜吉田松陽の弟子たち〜】'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='983'
            height='737'
            src='https://www.youtube.com/embed/AuqxF-AA3ik?list=LL'
            title='Yoga vs camus'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          ></iframe>
        </div>
      </section> */}
      </div>
      {/* <div className='flex items-center justify-center'>
        <Lottie animationData={AnimeGirl} loop={true} />
      </div> */}
    </>
  );
}

export default Home;
