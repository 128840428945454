import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import {
  PromoNav,
  Nav,
  Footer,
  KofiFloatingChatWidget,
  Home,
  Donate,
  ErrorPage,
} from './index';
import { Overlay } from './components/Overlay';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Hotjar from '@hotjar/browser';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
// import { Style } from '@/components/Style.tsx';
import loadable from '@loadable/component';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

/* #__LOADABLE__ */
const LoadableAbout = loadable(() => import('@/pages/About'));
const LoadableWorlds = loadable(() => import('@/pages/Worlds'));
// Load the component dynamically

function App() {
  const measurementId = import.meta.env.VITE_GA_MEASUREMENT_ID;
  ReactGA.initialize(measurementId);

  NProgress.configure({ showSpinner: false });

  const siteId = 5063671;
  const hotjarVersion = 6;
  
  Hotjar.init(siteId, hotjarVersion);

  return (
    <>
      <Router>
        {/* <Overlay /> */}
        <PromoNav />
        <Nav />
        <TrackPageViews />
        <TopLoader />
        <div>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<LoadableAbout />} />
            <Route path='/donate' element={<Donate />} />
            <Route path='/worlds' element={<LoadableWorlds />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </div>
        {/* <Footer /> */}
        
        <KofiFloatingChatWidget kofiId='milorr' />
      </Router>
      <SpeedInsights />
      <Analytics />
    </>
  );
}

function TrackPageViews() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return null;
}

function TopLoader() {
  const location = useLocation();
  useEffect(() => {
    NProgress.start();
    NProgress.done();
  }, [location]);
  return null;
}

export default App;
