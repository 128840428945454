import { useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import AnimeGirl from '@/assets/Girl.json';

// Styled component for Centered Content
const CenteredContent = styled.div`
  display: flex;
  padding-top: 5rem;
  margin-bottom: 5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  color: #fff;
`;

function ErrorPage() {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = '404 | Page Not Found';
    return () => {
      document.title = previousTitle;
    };
  }, []);

  return (
    <CenteredContent>
      <p>
        <strong>404</strong> | Page Not Found
      </p>
      <Lottie
        animationData={AnimeGirl}
        loop={true}
        className='h-full w-full'
        alt='404 Error'
      />
      <a href='https://store.ohmilo.xyz/'>Link</a>
    </CenteredContent>
  );
}

export default ErrorPage;
