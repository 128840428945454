// import { MusicButton } from './Howler';

// export const Nav = () => {
//   const footerLinks = [
//     { title: 'About', path: '/about' },
//     { title: 'Newsletter', path: '/' },
//     { title: 'Services', path: '/' },
//     { title: 'Contact', path: '/' },
//   ];
//   return (
//     <header className='fixed top-0 z-50 w-full transition-all duration-1000 ease-in-out bg-black'>
//       <nav
//         className='mx-auto flex items-center justify-between px-8 py-2'
//         aria-label='Global'
//       >
//         <div className='flex flex-col items-start gap-5 px-[23px] md:h-[60px] md:flex-row md:items-center md:gap-0 md:px-[65px]'>
//           <MusicButton soundSrc='/static/SaintSeiya.mp3' />
//           {/* Your logo/name on large screens */}
//           <div className='flex p-20 text-white lg:flex-1'>
//             <a
//               className='flex shrink-0 items-center justify-center gap-2 hover:scale-105'
//               href='/'
//               title={`homepage`}
//             >
//               <span
//                 className='text-lg font-extrabold'
//                 onClick={() => window.scrollTo(0, 0)}
//               >
//                 Milo
//               </span>
//             </a>
//           </div>

//           <ul className='hidden lg:flex lg:flex-1 lg:items-center lg:justify-center lg:gap-14'>
//             {footerLinks.map((link) => {
//               return (
//                 <li key={link.title}>
//                   <Link
//                     title={link.title}
//                     to={link.path}
//                     className='text-[15px] font-medium text-white transition-all duration-200 hover:text-indigo-500'
//                   >
//                     {link.title}
//                   </Link>
//                 </li>
//               );
//             })}
//           </ul>
//         </div>
//       </nav>
//     </header>
//   );
// };

import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '/src/assets/react.svg'; // Adjust path as needed
import { NAV_LINKS } from '@/index.ts';

// A header with a logo on the left, links in the center (like Pricing, etc...), and a CTA (like Get Started or Login) on the right.
// The header is responsive, and on mobile, the links are hidden behind a burger button.
export const Nav = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  // Close the menu when the route changes (i.e., when the user clicks on a link on mobile)
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <header className='sticky top-0 z-50 w-full text-black opacity-90 transition-all duration-1000'>
      <nav
        className='container mx-auto flex items-center justify-between px-8 py-4'
        aria-label='Global'
      >
        {/* Your logo/name on large screens */}
        <div className='flex lg:flex-1'>
          <Link
            to='/'
            className='flex shrink-0 items-center justify-center gap-2 hover:scale-105'
            title='Homepage'
          >
            <img
              src={logo}
              alt='logo'
              className='w-8'
              fetchPriority='high'
              width={32}
              height={32}
            />
            <span className='text-lg font-extrabold'>FastWebsite</span>
          </Link>
        </div>
        {/* Burger button to open menu on mobile */}
        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5'
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className='sr-only'>Open main menu</span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='text-base-content h-6 w-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
              />
            </svg>
          </button>
        </div>

        {/* Your links on large screens */}
        <ul className='hidden lg:flex lg:flex-1 lg:items-center lg:justify-center lg:gap-14'>
          {NAV_LINKS.map(({ label, href }) => (
            <li key={href}>
              <Link
                to={href}
                className='internal-link h-[1px] pb-2 border-b-2 border-transparent hover:text-indigo-500 hover:border-indigo-500'
                title={label}
                onClick={() => setIsOpen(false)}
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>

      {/* Mobile menu, show/hide based on menu state */}
      <div className={`relative z-50 ${isOpen ? '' : 'hidden'}`}>
        <div
          className={`navcolor sm:ring-neutral/10 fixed inset-y-0 right-0 z-10 w-full origin-right transform overflow-y-auto px-8 py-4 transition duration-300 ease-in-out sm:max-w-sm sm:ring-1`}
        >
          {/* Your logo/name on small screens */}
          <div className='min-width: 44px; min-height: 44px flex items-center justify-between'>
            <Link
              to='/'
              className='flex shrink-0 items-center gap-2'
              title='Homepage'
            >
              <img
                src={logo}
                alt='logo'
                className='w-8'
                fetchPriority='high'
                width={32}
                height={32}
              />
              <span className='text-lg font-extrabold'>FastWebsite</span>
            </Link>
            <button
              type='button'
              className='p-.5 -m-2.5 rounded-md'
              onClick={() => setIsOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='h-6 w-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>

          {/* Your links on small screens */}
          <div className='z-500 mt-6 flow-root'>
            <div className='py-4'>
              <div className='flex flex-col items-start gap-y-16 divide-y-2 divide-black/10'>
                {NAV_LINKS.map(({ label, href }) => (
                  <Link
                    to={href}
                    key={href}
                    className='link link-hover'
                    title={label}
                  >
                    {label}
                  </Link>
                ))}
              </div>
            </div>
            <div className='divider'></div>
          </div>
        </div>
      </div>
    </header>
  );
};
