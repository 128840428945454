import styled from 'styled-components';

const PageWrapper = styled.div`
  margin-top: 2rem;
  @media (max-width: 1000px) {
    padding: 0 0.5rem;
  }
`;

const FooterBaseContainer = styled.footer<{ $isSub: boolean }>`
  color: var(--global-text);
  padding: ${({ $isSub }) => ($isSub ? '0' : '0.5rem 0')};
  display: flex;
  justify-content: center;
  border-top: '0.125rem solid' : 'none')}
    var(--global-secondary-bg);
  align-items: center;
  flex-direction: column;

  @media (max-width: 1000px) {
    padding: '0 0 1rem 0' : '0.5rem 0')};
  }

  @media (min-width: 601px) {
    flex-direction: row;
  }

  @media (max-width: 600px) {
    padding: '0' : '0.5rem 0')};
  }
`;

const Text = styled.div`
  color: red;
  // font-size: '0.75rem' : '.65rem')};
  font-weight: '800' : '1000')};
  margin: '1rem 0 0 0' : '1rem 0')};
  max-width: 25rem;
  text-align: center;
  position: center;

  strong {
    color: var(--global-text);
  }
`;

export function Footer() {
  return (
    <PageWrapper>
      <div>
        <FooterBaseContainer aria-label='Main Footer' $isSub={false} className='bottom-0 left-0 right-0 z-50'>
          <Text>
            This website is made by Milo.
          </Text>
        </FooterBaseContainer>
      </div>
    </PageWrapper>
  );
}
