import { useEffect, useState } from 'react';
import shuffle from 'lodash/shuffle';
import styled from 'styled-components';
import { Button } from './ui/button';

// Create a <Title> react component that renders an <h1> which is
// centered, palevioletred and sized at 1.5em
const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  font-family: 'YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  color: white;
  font-weight: 200;
  letter-spacing: 0.1em;
`;

// Create a <Wrapper> react component that renders a <section> with
// some padding and a papayawhip background
const Wrapper = styled.section`
  padding: 1em;
  background: white;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  item-align: center;
  flex-wrap: wrap;
`;

const likes = shuffle([
  'writing JavaScript',
  'writing CSS',
  'writing HTML',
  'working on web apps',
  'using React',
  'using TailwindCSS',
  'learning new things',
  'accessibility',
  'using Lodash',
  'Sci-fi',
  'writing fast code',
  'cyberpunk games',
  'graphic designing',
  '3D modeling',
]);

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function MyLikes() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let running = true;

    void (async () => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      while (running) {
        await sleep(500);
        await sleep(1000);
        setIndex((index) => index + 1);
        await sleep(300);
      }
    })();

    return () => {
      running = false;
    };
  }, []);

  return (
    <Wrapper>
      <div className='flex items-center justify-center bg-white'>
        <Button className='h-12 w-80 rounded bg-blue-500 font-bold hover:bg-blue-700'>
          <Title>★I like {likes[index % likes.length]}</Title>
        </Button>
      </div>
    </Wrapper>
  );
}
