import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// Assuming that you have a component named 'Image' to display images
// const Image = ({ src, alt, className }) => (
//   <img src={src} alt={alt} className={className} />
// );
import { Button } from '@/components/ui/button';
import _ from 'lodash';

const SwiperContainerStyle = {
  position: 'relative',
  width: '1160px',
  height: '420px',
  cursor: 'grab',
  '@media (max-width: 1000px)': {
    height: '20rem',
  },
  '@media (max-width: 500px)': {
    height: '18rem',
  },
};

const SwiperSlideStyle = {
  justifyContent: 'flex-start',
  alignItems: 'center',
  '@media (max-width: 1000px)': {
    justifyContent: 'center',
    weight: '420px',
  },
};

function handlePopDetail(src) {
  const left = (window.screen.width - 550) / 2;
  const top = (window.screen.height - 600) / 2;
  const features = `
      width=400, 
      height=600, 
      left=${left}, 
      top=${top}, 
      resizable=yes, 
      scrollbars=no
    `;
  const popup = window.open(src, 'popup', features);
  if (popup) {
    popup.opener = null;
  }
  return popup;
}

export const Projects = () => {
  const projects = _.sortBy(
    [
      {
        id: '1',
        name: 'Tesla',
        description: '3D Slideshow with 3D Objects',
        url: 'https://tesla-orpin-rho.vercel.app/',
        source: 'https://github.com/itsMapleLeaf/reacord',
        imageSrc: '/static/tesla.png', // Use the correct path to your image
        releasedDate: '2024-03-20',
        status: 'Completed',
      },
      {
        id: '2',
        name: 'Spotify',
        description: 'Spotify Clone',
        url: 'https://myspotify-music.vercel.app/',
        imageSrc: '/static/Spotify.png', // Use the correct path
        status: 'Ongoing',
        releasedDate: '2024-03-20',
      },
      // {
      //   id: '3',
      //   name: 'Lucy',
      //   description: 'Edgerunners',
      //   url: '',
      //   imageSrc: '/static/lucy400h.png', // Use the correct path
      //   releasedDate: '2024-03-20',
      //   status: 'Ongoing',
      // },
      {
        id: '1',
        name: 'Tesla',
        description: '3D Slideshow with 3D Objects',
        url: 'https://tesla-orpin-rho.vercel.app/',
        source: 'https://github.com/itsMapleLeaf/reacord',
        imageSrc: '/static/tesla.png', // Use the correct path to your image
        releasedDate: '2024-03-20', // rea;
        status: 'Completed',
      },
      {
        id: '2',
        name: 'Spotify',
        description: 'Spotify Clone',
        url: 'https://myspotify-music.vercel.app/',
        imageSrc: '/static/Spotify.png', // Use the correct path
        status: 'Ongoing',
        releasedDate: '2024-03-20',
      },
      // {
      //   id: '3',
      //   name: 'Lucy',
      //   description: 'Edgerunners',
      //   url: '',
      //   imageSrc: '/static/lucy400h.png', // Use the correct path
      //   releasedDate: '2024-03-20',
      //   status: 'Ongoing',
      // },
    ],
    (project) => project.name.toLowerCase(),
  );

  const statusCounts = projects.reduce(
    (counts, project) => {
      if (project.status === 'Completed') {
        counts.completed++;
      } else if (project.status === 'Ongoing') {
        counts.ongoing++;
      }
      return counts;
    },
    { completed: 0, ongoing: 0 },
  );

  return (
    <>
      <div className='z-0 stats group relative grid overflow-clip sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
        <Swiper
          style={SwiperContainerStyle}
          spaceBetween={10}
          slidesPerView={3}
          loop={true}
          modules={[Autoplay]}
          // pagination={{
          //   clickable: true,
          //   type: "bullets",
          //   dynamicBullets: true,
          // }}
          autoplay={{
            delay: 900,
            disableOnInteraction: false,
          }}
          className='swiper-container'
        >
          {projects.map((project) => (
            <SwiperSlide
              style={SwiperSlideStyle}
              key={project.id}
              className='rounded-2xl p-4'
            >
              <article className='card relative grid gap-3 overflow-hidden rounded-2xl pb-10 text-white'>
                <img
                  src='https://act-webstatic.hoyoverse.com/puzzle/hkrpg/pz_GTIIox0eXG/resource/puzzle/2024/06/03/d2e482d7eae2a105f46464dfee6d9ba2_6175325920621393649.png?x-oss-process=image/format,webp/quality,Q_90'
                  className='absolute h-full w-full bg-white object-cover'
                  alt='a screenshot of the game'
                  fetchPriority='high'
                  height={'300'}
                  width='500'
                ></img>
                <img
                  src={project.imageSrc}
                  alt={`a screenshot of ${project.name}`}
                  className='absolute rounded-md object-cover transition-all duration-200 ease-in-out hover:opacity-90'
                  height='300'
                  width='500'
                  fetchPriority='high'
                  onClick={() => handlePopDetail(project.url)}
                />
                <article className='absolute bottom-0 left-0 right-0 flex flex-col gap-2 bg-black/70 p-4'>
                  <h4 className='font-condensed text-2xl font-light'>
                    <a
                      href={project.url}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='hover link'
                    >
                      {project.name}
                    </a>
                  </h4>
                  <p className='relative'>{project.description}</p>
                  <div>
                    <p>
                      Status:
                      <Button
                        className='button #191919 left-1 h-2 w-24'
                        variant='gooeyLeft'
                      >
                        {project.status}
                      </Button>
                    </p>
                    <p>
                      Released Date:
                      <Button
                        className='button #191919 left-1 h-2 w-24 text-blue-200'
                        variant='gooeyLeft'
                      >
                        {project.releasedDate}
                      </Button>
                    </p>
                  </div>
                  <div className='flex items-center justify-center gap-2 opacity-90'>
                    <Button
                      className='button w-24 bg-white text-black'
                      variant='gooeyLeft'
                    >
                      Link
                    </Button>
                    <Button
                      onClick={() => handlePopDetail(project.url)}
                      className='button w-24 bg-white text-black'
                      variant='gooeyLeft'
                    >
                      Open Popup
                    </Button>
                    <Button
                      onClick={() => handlePopDetail(project.url)}
                      className='button w-24 bg-white text-black'
                      variant='gooeyLeft'
                    >
                      Gallery
                    </Button>
                  </div>
                </article>
              </article>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='grid gap-4 p-3 text-white sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
        {projects.map((project) => (
          <article className='card-2 color grid items-center justify-center gap-3 rounded-2xl bg-gray-300 p-2.5 shadow hover:scale-105 hover:shadow-xl'>
            <h4 className='font-condensed text-2xl font-light'>
              <a
                href={project.url}
                target='_blank'
                rel='noopener noreferrer'
                className='hover link'
              >
                {project.name}
              </a>
            </h4>
            <picture className='aspect-[18/9]'>
              <img
                src={project.imageSrc}
                alt={`a screenshot of ${project.name}`}
                className='aspect-[18/9] rounded-md object-cover'
                fetchPriority='high'
                height='300'
                width='500'
              />
            </picture>
            <p>{project.description}</p>
            <Button className='w-24 bg-white text-black' variant='gooeyRight'>
              <a href={project.url} target='_blank'>
                Link
              </a>
            </Button>
          </article>
        ))}
      </div>
    </>
  );
};
