import { Button } from '@/components/ui/button';

export const Sniply = () => {
  return (
    <div className='sniply flex flex-col items-center justify-center text-orange-500 rounded-2xl'>
      <span className='mr-12'>Shopify</span>
      <Button className='font-bold ml-14 bg-transparent text-orange-700' variant='gooeyRight'>
        Come Visit Our Store!
      </Button>
      <Button className='bg-orange-500 text-white' variant='pulse'>
        BUTTON
      </Button>
    </div>
  );
}