// * ==== Pages ====
// TODO: Main Pages of the Application
export { default as Home } from '@/pages/Home';
// export { default as About } from '@/pages/About';
export { default as ErrorPage } from '@/pages/404';
export { default as Donate } from '@/pages/Donate';
// * ==== Components ====
// TODO: Main Components of the Application
export { PromoNav } from '@/components/PromoNav';
export { Nav } from '@/components/Nav';
export { Footer } from '@/components/Footer';
export { KofiFloatingChatWidget } from '@/components/Kofi';
export { MusicButton } from '@/components/Howler';
export { Sniply } from '@/components/Sniply';

// * ==== Navigation ====
// TODO: Navigation Links of the Application
export const NAV_LINKS = [
  {
    href: '/home',
    label: 'Home',
  },
  {
    href: '/donate',
    label: 'Donate',
  },
  {
    href: '/about',
    label: 'About',
  },
  {
    href: '/worlds',
    label: 'Worlds',
  },
  {
    href: '/store',
    label: 'Store',
  },
];